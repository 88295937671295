import React from 'react'

import { Button } from '../components/Button'
import { Form, FormItem } from '../components/Form'
import { Input } from '../components/Input'
import { NoAuthLayout } from '../components/LayoutContainer'
import SEO from '../components/SEO'

import { requestPasswordReset } from '../services/strapi/api'

import { useForm } from '../hooks/useForm'

const ForgotPasswordForm = () => {
  const [form] = useForm()
  const [error, setError] = React.useState()
  const [isBusy, setBusy] = React.useState(false)
  const [isDone, setDone] = React.useState(false)

  const onFinish = values => {
    setError(null)
    setBusy(true)

    const { email } = values
    requestPasswordReset({ email }).then(res => {
      setBusy(false)

      if (res.status === 200) {
        setDone(true)
      } else {
        setError('Unknown email.')
      }
    })
  }

  if (isDone) {
    return (
      <p>
        {`An email has been sent with instructions to reset your password.`}
      </p>
    )
  }

  return (
    <div>
      <Form form={form} onFinish={onFinish}>
        <FormItem name="email" required>
          <Input placeholder="Email" />
        </FormItem>

        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            loading={isBusy}
            disabled={isBusy}
          >
            Submit
          </Button>
        </FormItem>

        {error && <div style={{ color: '#F33' }}>{error}</div>}
      </Form>
    </div>
  )
}

const ForgotPasswordPage = () => (
  <NoAuthLayout>
    <SEO title="Forgot Password" />
    <div className="content">
      <div style={{ margin: '3rem auto', maxWidth: 600 }}>
        <h2>Forgot Password</h2>
        <ForgotPasswordForm />
      </div>
    </div>
  </NoAuthLayout>
)

export default ForgotPasswordPage
